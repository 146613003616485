export enum PORTALVIEW {
    LOGIN = "LOGIN",
    REGISTER = "REGISTER",
    LOGGEDIN = "LOGGEDIN",
    RESETPASSWORD = "RESETPASSWORD",
    GAMESHIFT_ACTIVATION = "GAMESHIFT_ACTIVATION",
}

export type authProps = {
    isUserSignedIn: boolean
    firstName: string | null
    lastName: string | null
    email: string | null
    playFabId: string | null
    portalView: PORTALVIEW
    displayName: string | null
    username: string | null
    marks: number
    playerTitleId: string | null
    shards: number
    credits: number
    energy: number
    seasonPass: number | null
    solanaWallet: string | null
    solanaWalletType: string | null
}

export type loginProps = {
    Username: string
    Password: string
    TitleId: string
}

export type Player = {
    userName: string
    rank: number
    kills: number
    tier: string
}

export type loginPayload = {
    usernameOrEmail: string
    password: string
    TitleId: string
}

export type CreateAccountProps = {
    username: string
    email: string
    password: string
    TitleId: string
    RequireBothUsernameAndEmail: boolean
    EncryptedRequest: string
}

export type ForgotPasswordProps = {
    email: string
    TitleId: string
}

export type gameShiftActivationPayload = {
    titleId: string
    email: string
    gameShiftReferenceId: string
}

export type GameShiftEventProps = {
    source: string | null
    action: string | null
    noun: string | null
    userKey: string | null
    pubKey: string | null
    referenceId: string | null
    referer: string | null
    metadata?: string | null
}
