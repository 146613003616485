"use client"
import React from "react"
import Link from "next/link"
import { PORTALVIEW } from "@/types/types.auth"
import { useRouter } from "next/navigation"
import { MdKeyboardArrowRight } from "react-icons/md"
import { BUTTONTYPE, ButtonComponent } from "../ButtonUIComponent"
import { useAppDispatch, useAppSelector } from "@/app/redux/hooks/reduxHooks"

const ProfileComponent = () => {
    const auth = useAppSelector((state) => state.auth)
    const dispatch = useAppDispatch()
    const router = useRouter()

    const logout = async () => {
        try {
            const response = await fetch("/api/logout", { method: "POST" })
            if (response.ok) {
                dispatch({ type: "auth/signOut" })
                router.push("/")
            }
        } catch (error) {
            console.error("Error logging out user", error)
        }
    }

    return (
        <>
            {auth.isUserSignedIn ? (
                <Link href="/auth/account">
                    <div className="flex">
                        <div className="flex items-center justify-center gap-2 hover:text-white transition-all">
                            <div className="relative text-sm top-[.5px] text-white">
                                {auth.username ?? auth.displayName ?? auth.email}
                            </div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                                className="text-white"
                            >
                                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                <path
                                    fillRule="evenodd"
                                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                                />
                            </svg>
                        </div>
                        <ButtonComponent
                            text="LOG OUT"
                            type={BUTTONTYPE.UNDERLINE}
                            htmlType="button"
                            onClick={() => logout()}
                            icon={<MdKeyboardArrowRight />}
                            iconPosition="right"
                            className="pl-5 text-primary cursor-pointer"
                        />
                    </div>
                </Link>
            ) : (
                <Link
                    href="/auth/login"
                    onClick={() => dispatch({ type: "auth/changePortalView", payload: PORTALVIEW.LOGIN })}
                >
                    <ButtonComponent
                        text="LOG IN"
                        type={BUTTONTYPE.BORDER}
                        htmlType="button"
                        icon={<MdKeyboardArrowRight />}
                        iconPosition="right"
                        className="mt-[10px] cursor-pointer"
                    />
                </Link>
            )}
        </>
    )
}

export default ProfileComponent
