"use client"
import React from "react"
import Image from "next/image"
import { branding, routes } from "@/app/api/meta"
import { SheetContent, SheetHeader, SheetClose, SheetTitle } from "@/components/ui/sheet"
import Link from "next/link"
import ProfileComponent from "./ProfileUIComponent"
import { useAppSelector } from "@/app/redux/hooks/reduxHooks"

const SheetComponent = () => {
    const isUserSignedIn = useAppSelector((state) => state.auth.isUserSignedIn)
    return (
        <SheetContent side="left" className="bg-[rgba(0,0,0,0.9)] border-r-[#222]">
            <SheetHeader className="pb-5">
                <SheetTitle className="text-[14px] text-left text-white">Portal</SheetTitle>
                <Link href={isUserSignedIn ? "/dashboard" : "/"}>
                    <SheetClose asChild>
                        <Image width="120" height="32" src={branding.mainLogo.path} alt={branding.mainLogo.alt} />
                    </SheetClose>
                </Link>
            </SheetHeader>
            <div className="pb-10">
                <ProfileComponent />
            </div>
            <ul>
                {routes.map((route, index) => {
                    return (
                        <li key={route.path} className="py-2">
                            <SheetClose asChild>
                                <Link href={route.path} className="text-white hover:text-primary transition-all">
                                    {" "}
                                    <small className="relative right-1 bottom-1 text-[10px]">00{index + 1}</small>
                                    {route.title}
                                </Link>
                            </SheetClose>
                        </li>
                    )
                })}
            </ul>
        </SheetContent>
    )
}

export default SheetComponent
