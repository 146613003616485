"use client"
import { useAppSelector } from "@/app/redux/hooks/reduxHooks"
import { useRouter, useSearchParams } from "next/navigation"
import { useEffect } from "react"
import Image from "next/image"
import Link from "next/link"

export default function Home() {
    const auth = useAppSelector((state) => state.auth)
    const router = useRouter()
    const searchParams = useSearchParams()

    useEffect(() => {
        if (auth.isUserSignedIn) {
            router.push("/dashboard")
        }
    }, [router, auth.isUserSignedIn])

    return (
        <main>
            <section className="bg-[url('/background.webp')] py-10 h-min-screen bg-cover bg-right-bottom">
                <section className="mx-auto max-w-[1200px] p-5">
                    <h1 className="text-white text-lg py-8 text-center">METALCORE PORTAL</h1>
                    <div className="block sm:grid grid-cols-3 gap-5">
                        <div className="h-[80px] sm:h-[600px] mb-5 sm:mb-0 group bg-[#000] sm:bg-[url('/MC_PortalPage_Banner_1.webp')] bg-center bg-no-repeat sm:bg-cover flex items-center justify-center relative">
                            <Link href="/missions">
                                <h2 className="text-white uppercase group group-hover:text-primary tracking-wider text-[31px] relative z-10 transition-all">
                                    Missions
                                </h2>
                            </Link>
                            <div className="bg-[#000] group-hover group-hover:bg-opacity-70 bg-opacity-20 absolute inset-0 z-0 transition-all">
                                &nbsp;
                            </div>
                        </div>
                        <div className="h-[80px] sm:h-[600px] mb-5 sm:mb-0 group bg-[#000] sm:bg-[url('/MC_PortalPage_Banner_2.webp')] bg-center bg-no-repeat sm:bg-cover flex items-center justify-center relative">
                            <Link href="/store">
                                <h2 className="text-white uppercase group group-hover:text-primary tracking-wider text-[31px] relative z-10 transition-all">
                                    Store
                                </h2>
                            </Link>
                            <div className="bg-[#000] group-hover group-hover:bg-opacity-70 bg-opacity-20 absolute inset-0 z-0 transition-all">
                                &nbsp;
                            </div>
                        </div>
                        <div className="h-[80px] sm:h-[600px] mb-5 sm:mb-0 group bg-[#000] sm:bg-[url('/MC_PortalPage_Banner_3.webp')] bg-center bg-no-repeat sm:bg-cover flex items-center justify-center relative">
                            <Link href="/auth/account">
                                <h2 className="text-white uppercase group group-hover:text-primary tracking-wider text-[31px] relative z-10 transition-all">
                                    Account
                                </h2>
                            </Link>
                            <div className="bg-[#000] group-hover group-hover:bg-opacity-70 bg-opacity-20 absolute inset-0 z-0 transition-all">
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </main>
    )
}
