/* Core */
import { authSlice } from "./slices/slice.auth"
import { configureStore } from "@reduxjs/toolkit"
import { dashboardSlice } from "./slices/slice.dashboard"
import { missionSlice } from "./slices/slice.missions"

export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        dashboard: dashboardSlice.reducer,
        missions: missionSlice.reducer,
    },
})

/* Types */
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
