import { DIALOG } from "@/lib/missions/dialog"

export type NFTProps = {
    success: boolean
    data: {
        page: {
            next_cursor: string | number | null
            previous_cursor: string | number | null
        }
        result: IMXNFTAttributes[]
    }
}

export type IMXNFTAttributes = {
    animation_url: string | null
    attributes: {
        trait_type: string
        value: string
    }[]
    balance: string
    chain: {
        id: string
        name: string
    }
    contract_address: string
    contract_type: string
    description: string | null
    external_link: string | null
    image: string
    indexed_at: string
    metadata_id: string
    name: string
    token_id: string
    updated_at: string
    youtube_url: string | null
    web_image: string
    internalId: string
}

export type EliteTimerProps = {
    missionDetails: {
        status: string
    }
    offCooldown: string
    offMissionCooldownTime: string
}

export type MissionableNFTProps = {
    catalogId: string
    id: string
    image_url: string
    web_image: string
    nftRarity: string
    nftType: string
    nftWeight: number
    missionDetails: {
        cred: number
        critCred: number
        missionDescription: string
        status: string
        critMarks: number
        critPct: number
        energy: number
        marks: number
        xp?: number
        actions: {
            verb: string
        }[]
        displayedActions: {
            costs: {
                shards?: number
                credits?: number
                energy?: number
            }
            display: string
            effects?: {
                stat: string
                value: number
                multiply: boolean
            }[]
            failureChance?: number
            verb: string
        }[]
    }
    offCooldown: string
    offMissionCooldownTime: string
}

export type MissionableResponse = {
    error?: {
        code: number
        error: string
        errorCode: number
        errorMessage: string
        status: string
    }
    success?: boolean
    message?: string
    unitData: MissionableNFTProps[]
    totalUnits: number
    currency: {
        Etag: string
        Items: CurrencyArray[]
    }
}

export type CurrencyArray = {
    Amount: number
    DisplayProperties: {}
    Id: string
    StackId: string
    Type: string
}

export type ActionProps = {
    playerTitleId: string
    playerId: string
    nftId: string
    action: string
}

export type MissionResponse = {
    data: {
        cooldownTime: string
        missionDetails: {
            actions: {
                verb: string
            }[]
            cred: number
            critCred: number
            critMarks: number
            critPct: number
            energy: number
            marks: number
            status: string
            xp: number
            missionDescription: string
            displayedActions: {
                verb: string
                display: string
                failureChance: number
                costs: {
                    energy?: number
                    shards?: number
                    credits?: number
                }
                effects: {
                    stat: string
                    value: number
                    multiply: boolean
                }[]
            }[]
        }
        missionResult: {
            credits: number
            description: string
            marks: number
            shards?: number
            xp: number
        }
    }
    success: boolean
}

export enum MISSIONS_VIEW_STATE {
    HOME,
    MISSIONS,
    EARN,
    SHOP,
}

export enum MISSIONS_TYPE {
    DEFAULT = "Default",
    STANDARD = "Standard",
    AIRCRAFT = "Aircraft",
    MECH = "Mech",
    FRONTLINE = "Frontline",
    ACTIVE_FRONTLINE = "ActiveFrontline",
    CODERED = "CodeRed",
    SIEGE = "Siege",
    MECHATTACK = "MechAttack",
}

export type MissionProps = {
    payload: {
        dialog: string | null
        choice1: {
            text: string | null
            key: DIALOG | null
        }
        choice2: {
            text: string | null
            key: DIALOG | null
        }
    }
    viewState: MISSIONS_VIEW_STATE
    missionsViewState: MISSION_VIEW
    type: MISSIONS_TYPE
    showCypher: boolean
}

export enum MISSION_VIEW {
    MAIN,
    OVERVIEW,
    SELECTEDVIEW,
    ELITE,
}

export type CostsProps = {
    shards?: number
    credits?: number
    energy?: number
}

export type actionProps = {
    costs: {
        shards?: number
        credits?: number
        energy?: number
    }
    display: string
    effects?: {
        stat: string
        value: number
        multiply: boolean
    }[]
    failureChance?: number
    verb: string
}

export type EarnProps = {
    playerTitleId: string
    playFabId: string
    campaign: string
    resource: string
    amount: number
}
