"use client"

import { usePathname } from "next/navigation"
import { useEffect, useState } from "react"

export const excluder = () => {
    const pathname = usePathname()
    const [exclude, setExclude] = useState<boolean>(false)
    const exclusions = [
        "/missions",
        "/gameshift-activation",
        "/auth/login?gameshift-activation=true",
        "/solana-integration-page",
        "/gameshift-activation/thank-you",
        "/verification/success",
        "/verification/failed",
    ]

    useEffect(() => {
        for (let i = 0; i < exclusions.length; i++) {
            if (exclusions[i] === pathname) {
                setExclude(true)
                break
            } else {
                setExclude(false)
            }
        }
    }, [pathname])

    return {
        exclude,
    }
}
