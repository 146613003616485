import React from "react"
import CountUp from "react-countup"
import Link from "next/link"

type Props = {
    auth: {
        marks: number
        shards: number
        credits: number
    }
}

export const HeaderCurrencyComponent: React.FC<Props> = (props) => {
    const { auth } = props
    return (
        <section>
            <div className="mx-auto max-w-[1200px] px-6 py-4 grid grid-cols-2 sm:flex gap-1 sm:gap-7">
                <div>
                    <Link href="/store" className="bg-primary px-2 text-black">
                        BUY SHARDS
                    </Link>
                </div>
                <div className="min-w-[120px] flex gap-2 text-white">
                    <h2>SHARDS</h2>
                    <div>
                        <CountUp end={auth.shards} />
                    </div>
                </div>
                <div className="min-w-[120px] flex gap-2 text-white">
                    <h2>CREDITS</h2>
                    <div>
                        <CountUp end={auth.credits} />
                    </div>
                </div>
                <div className="min-w-[120px] flex gap-2 text-white">
                    <h2>MARKS</h2>
                    <div>
                        <CountUp end={auth.marks} />
                    </div>
                </div>
            </div>
        </section>
    )
}
