import { createSlice } from "@reduxjs/toolkit"
import { dashboardProps } from "@/types/types.dashboard"

const initialState: dashboardProps = {
    rank: null,
    nextRank: null,
    seasonPassProgress: null,
    creditsUntilPromotion: null,
    currentRankIcon: null,
    bronzeTier: null,
    silverTier: null,
    goldTier: null,
    promoModal: false,
}

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        setSeasonInfo: (state, action) => {
            state.rank = action.payload.rank
            state.nextRank = action.payload.nextRank
            state.creditsUntilPromotion = action.payload.creditsUntilPromotion
            state.currentRankIcon = action.payload.currentRankIcon
            state.bronzeTier = action.payload.bronze
            state.silverTier = action.payload.silver
            state.goldTier = action.payload.gold
        },
        setPromoModal: (state, action) => {
            state.promoModal = action.payload
        },
    },
})

export const { setSeasonInfo, setPromoModal } = dashboardSlice.actions
