import { createSlice } from "@reduxjs/toolkit"
import { PORTALVIEW, authProps } from "@/types/types.auth"

const initialState: authProps = {
    isUserSignedIn: false,
    firstName: null,
    lastName: null,
    email: null,
    playFabId: null,
    portalView: PORTALVIEW.LOGIN,
    displayName: null,
    username: null,
    marks: 0,
    shards: 0,
    credits: 0,
    playerTitleId: null,
    energy: 0,
    seasonPass: null,
    solanaWallet: null,
    solanaWalletType: null,
}

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        signIn: (state, action) => {
            state.isUserSignedIn = true
            state.portalView = PORTALVIEW.LOGGEDIN
            state.username =
                action.payload.playerInformation.data.AccountInfo.Username ||
                action.payload.playerInformation.data.AccountInfo.TitleInfo.DisplayName ||
                action.payload.playerInformation.data.AccountInfo.PrivateInfo.Email
            state.displayName = action.payload.playerInformation.data.AccountInfo.TitleInfo.DisplayName
            state.email = action.payload.playerInformation.data.AccountInfo.PrivateInfo.Email
            state.playFabId = action.payload.playerInformation.data.AccountInfo.PlayFabId
            state.playerTitleId = action.payload.playerInformation.data.AccountInfo.TitleInfo.TitlePlayerAccount.Id
            state.marks =
                action.payload.playerStatisticsData &&
                action.payload.playerStatisticsData.data.Statistics.find(
                    (stat: { StatisticName: string }) => stat.StatisticName === "RewardPool_Marks"
                )
                    ? action.payload.playerStatisticsData.data.Statistics.find(
                          (stat: { StatisticName: string }) => stat.StatisticName === "RewardPool_Marks"
                      ).Value
                    : 0
            state.seasonPass =
                action.payload.playerStatisticsData &&
                action.payload.playerStatisticsData.data.Statistics.find(
                    (stat: { StatisticName: string }) => stat.StatisticName === "SeasonPass"
                )
                    ? action.payload.playerStatisticsData.data.Statistics.find(
                          (stat: { StatisticName: string }) => stat.StatisticName === "SeasonPass"
                      ).Value
                    : 0
            state.shards =
                action.payload.currency &&
                action.payload.currency.result.data.Items.find(
                    (currency: { Id: string }) =>
                        currency.Id ===
                        `${
                            process.env.NEXT_PUBLIC_ENV === "development"
                                ? "1a4387d0-fed0-485b-8e05-222e7ee8abbf"
                                : "1503d9ed-3bf2-464e-a5b5-9a7502b43327"
                        }`
                )
                    ? action.payload.currency.result.data.Items.find(
                          (currency: { Id: string }) =>
                              currency.Id ===
                              `${
                                  process.env.NEXT_PUBLIC_ENV === "development"
                                      ? "1a4387d0-fed0-485b-8e05-222e7ee8abbf"
                                      : "1503d9ed-3bf2-464e-a5b5-9a7502b43327"
                              }`
                      ).Amount
                    : 0
            state.credits =
                action.payload.currency &&
                action.payload.currency.result.data.Items.find(
                    (currency: { Id: string }) =>
                        currency.Id ===
                        `${
                            process.env.NEXT_PUBLIC_ENV === "development"
                                ? "c7872344-7958-4ff3-b035-8f39b6500bd8"
                                : "f854f0ab-ef7b-4aeb-bb70-d745474e34db"
                        }`
                )
                    ? action.payload.currency.result.data.Items.find(
                          (currency: { Id: string }) =>
                              currency.Id ===
                              `${
                                  process.env.NEXT_PUBLIC_ENV === "development"
                                      ? "c7872344-7958-4ff3-b035-8f39b6500bd8"
                                      : "f854f0ab-ef7b-4aeb-bb70-d745474e34db"
                              }`
                      ).Amount
                    : 0
        },
        signOut: (state) => {
            state.isUserSignedIn = false
            state.playFabId = null
            state.portalView = PORTALVIEW.LOGIN
            state.shards = 0
            state.credits = 0
            state.marks = 0
            state.energy = 0
        },
        assignAccountInfo: (state, action) => {
            state.isUserSignedIn = true
            state.email = action.payload.PrivateInfo.Email
            state.username = action.payload.Username
            state.playFabId = action.payload.PlayFabId
            state.portalView = PORTALVIEW.LOGGEDIN
        },
        changePortalView: (state, action) => {
            state.portalView = action.payload
        },
        setEnergy: (state, action) => {
            state.energy = action.payload
        },
        setShards: (state, action) => {
            state.shards = action.payload
        },
        setMarks: (state, action) => {
            state.marks = action.payload
        },
        setCredits: (state, action) => {
            state.credits = action.payload
        },
        setSeasonPass: (state, action) => {
            state.seasonPass = action.payload
        },
        setSolanaWallet: (state, action) => {
            state.solanaWallet = action.payload.solanaWallet
            state.solanaWalletType = action.payload.solanaWalletType
        },
    },
})

export const {
    signIn,
    signOut,
    assignAccountInfo,
    setShards,
    setEnergy,
    setMarks,
    setCredits,
    setSeasonPass,
    setSolanaWallet,
} = authSlice.actions
