import React, { forwardRef } from "react"

type ButtonProps = {
    text: string
    onClick?: () => void
    type: BUTTONTYPE
    className?: string
    htmlType: "button" | "submit" | "reset" | undefined
    icon?: any
    iconPosition?: "left" | "right"
    disabled?: boolean
}

export enum BUTTONTYPE {
    FILLED,
    BORDER,
    UNDERLINE,
}

export const ButtonComponent = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const { text, onClick, type, className, icon, htmlType, iconPosition, disabled } = props

    const styleType = () => {
        switch (type) {
            case BUTTONTYPE.FILLED:
                return `flex items-center justify-center text-black px-4 py-[5px] mt-8 transition-all ${
                    disabled ? "bg-slate-500 cursor-not-allowed" : "bg-primary hover:bg-primary-light cursor-pointer"
                }`
            case BUTTONTYPE.BORDER:
                return "flex items-center justify-center bg-transparent text-white border border-white px-4 py-2 mt-8 hover:bg-white hover:text-black transition-all cursor-pointer"
            case BUTTONTYPE.UNDERLINE:
                return "flex items-center justify-center hover:underline hover:text-white transition-all cursor-pointer"
        }
    }

    return (
        <button
            onClick={() => (onClick ? onClick() : null)}
            className={`${styleType()} + ${className}`}
            type={htmlType}
            disabled={disabled}
            ref={ref}
        >
            {icon && iconPosition === "left" && icon}
            {text}
            {icon && iconPosition === "right" && icon}
        </button>
    )
})
